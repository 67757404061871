import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { base } from '@sdxcorp/sdx-dds-gui-lib';

const {
  organisms: { LayoutContent },
  atoms: { Heading },
} = base;

interface LoadingProps {
  messageKey?: string;
  delay?: number;
}

export const Loading: React.FC<LoadingProps> = React.memo(({ messageKey = 'loading' }) => {
  const { t } = useTranslation();
  // @ts-ignore
  return <div>{t(messageKey)}</div>;
});

export const PageLoading: React.FC<LoadingProps> = React.memo(({ messageKey = 'loading', delay = 750 }) => {
  const { t } = useTranslation();
  const [showMsg, setShowMsg] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => {
    if (timer.current !== undefined) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }

    timer.current = setTimeout(() => {
      setShowMsg(true);
    }, delay);

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
    };
  }, [delay]);

  if (!showMsg) {
    return null;
  }
  // @ts-ignore
  const Msg = <Heading level="1">{t(messageKey)}</Heading>;
  return <LayoutContent>{Msg}</LayoutContent>;
});
