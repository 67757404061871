import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { PageLoading } from '../base/components/atoms/Loading';
import { Paths } from '../routing/routes';

const COMPONENTS = Object.freeze({
  mainRegister: React.lazy(() => import('./Pages/MainRegister/MainRegisterOverview')),
  currencyHolidays: React.lazy(() => import('./Pages/CurrencyHolidays/CurrencyHolidaysOverview')),
  bonds: React.lazy(() => import('./Pages/FINMA/bonds/BondsOverview')),
  etps: React.lazy(() => import('./Pages/FINMA/etps/EtpsOverview')),
  shares: React.lazy(() => import('./Pages/FINMA/shares/SharesOverview')),
  bond: React.lazy(() => import('./Pages/FINMA/bonds/BondView')),
  share: React.lazy(() => import('./Pages/FINMA/shares/ShareView')),
  etp: React.lazy(() => import('./Pages/FINMA/etps/EtpView')),
  news: React.lazy(() => import('./Pages/FINMA/news/NewsOverview')),
  mistrade: React.lazy(() => import('./Pages/FINMA/news/Mistrade')),
  suspension: React.lazy(() => import('./Pages/FINMA/news/Suspension')),
  documents: React.lazy(() => import('./Pages/FINMA/documents/DocumentsOverview')),
  officialNotices: React.lazy(() => import('./Pages/FINMA/official-notices/OfficialNoticesOverview')),
  officialNotice: React.lazy(() => import('./Pages/FINMA/official-notices/OfficialNoticeView')),
});

const Routes = () => {
  return (
    <Suspense fallback={<PageLoading />}>
      <Route path={Paths.mainRegister} exact={true} component={COMPONENTS.mainRegister} />
      <Route path={Paths.currencyHolidays} exact={true} component={COMPONENTS.currencyHolidays} />
      <Route path={Paths.bonds} exact={true} component={COMPONENTS.bonds} />
      <Route path={Paths.bond} exact={true} component={COMPONENTS.bond} />
      <Route path={Paths.etps} exact={true} component={COMPONENTS.etps} />
      <Route path={Paths.etp} exact={true} component={COMPONENTS.etp} />
      <Route path={Paths.shares} exact={true} component={COMPONENTS.shares} />
      <Route path={Paths.share} exact={true} component={COMPONENTS.share} />
      <Route path={Paths.news} exact={true} component={COMPONENTS.news} />
      <Route path={Paths.mistrade} exact={true} component={COMPONENTS.mistrade} />
      <Route path={Paths.suspension} exact={true} component={COMPONENTS.suspension} />
      <Route path={Paths.documents} exact={true} component={COMPONENTS.documents} />
      <Route path={Paths.officialNotices} exact={true} component={COMPONENTS.officialNotices} />
      <Route path={Paths.officialNotice} exact={true} component={COMPONENTS.officialNotice} />
    </Suspense>
  );
};

export default Routes;
