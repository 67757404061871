import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
import { ddsGuiApolloClientLinks, ddsGuiTypePolicies } from '@sdxcorp/sdx-dds-gui-lib';
import { ApolloLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

const restLink = new RestLink({
  // eslint-disable-next-line no-restricted-globals
  uri: location.origin + '/',
});

const cache = new InMemoryCache({
  possibleTypes: {
    GenericAsset: ['Currency', 'Security'],
    SettlementInstruction: ['FoPSettlementInstruction', 'TradeSettlementInstruction'],
  },
  typePolicies: ddsGuiTypePolicies,
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([restLink, ...ddsGuiApolloClientLinks()]),
  cache,
});

export default apolloClient;
